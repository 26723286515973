export default class ErpWarehouseEntity {
    static getEntityData(elem: any) {
        return {
            id: elem.querySelector("#erp_warehouse_id") ? (elem.querySelector("#erp_warehouse_id") as HTMLInputElement).value : null,
            config: elem.querySelector("#erp_warehouse_config") ? (elem.querySelector("#erp_warehouse_config") as HTMLInputElement).value : [],
            name: (elem.querySelector("#erp_warehouse_name") as HTMLInputElement).value,
            shortcode: (elem.querySelector("#erp_warehouse_shortcode") as HTMLInputElement).value,

            company: (elem.querySelector("#erp_warehouse_company") as HTMLInputElement).value,
            street: (elem.querySelector("#erp_warehouse_street") as HTMLInputElement).value,
            streetNumber: (elem.querySelector("#erp_warehouse_streetNumber") as HTMLInputElement).value,
            streetAdditional: (elem.querySelector("#erp_warehouse_streetAdditional") as HTMLInputElement).value,
            postcode: (elem.querySelector("#erp_warehouse_postcode") as HTMLInputElement).value,
            city: (elem.querySelector("#erp_warehouse_city") as HTMLInputElement).value,
            email: (elem.querySelector("#erp_warehouse_email") as HTMLInputElement).value,
            telephone: (elem.querySelector("#erp_warehouse_telephone") as HTMLInputElement).value,
            fax: (elem.querySelector("#erp_warehouse_fax") as HTMLInputElement).value,
            employeeCount: parseInt((elem.querySelector("#erp_warehouse_employeeCount") as HTMLInputElement).value),
            size: (elem.querySelector("#erp_warehouse_size") as HTMLInputElement).value,
            lkwRamp: (elem.querySelector("#erp_warehouse_lkwRamp") as HTMLInputElement).checked,
            openingHours: (elem.querySelector("#erp_warehouse_openingHours") as HTMLInputElement).value,
        }
    }
}